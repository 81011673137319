import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Row, Col, ProductBookCard, WhatNext } from '../components/global'
import config from '../siteConfig'

const AllTraining = () => (
  <Layout>
    <SEO title="All Traning" />
    <div className="container container-last">
      <h1 className="text-center">
        All Veejer Enterprises, Inc.
        <br />
        Training Materials
      </h1>
      <hr />
      <h2 className="text-center">New Products</h2>
      <Row>
        <Col width="32%">
          <ProductBookCard
            title="Advanced Electronics Training, Part 1"
            back={`50 pages with over 33 illustrations of schematics and schemations presenting how the
            heart of a vehicle's electrical-electronic system functions with instructions how to
            troubleshooting this circuit.`}
            productLink="/products/advanced-electronics-training-part-1"
            step="Book"
          />
        </Col>
      </Row>
      <hr />
      <h2 className="text-center">Most Popular Products</h2>
      <p className="text-center">
        These two books and hands-on training kit are the perfect starting point for technicians,
        shop owners, and teachers to engage with our training material.
      </p>
      <Row>
        <Col width="32%">
          <ProductBookCard
            title="<em>SHORTCUTS</em>"
            back={`Electrical <em>SHORTCUTS</em> shows a technician how to troubleshoot vehicle electrical circuits on-the-vehicle with just a DMM and Current Clamp.`}
            productLink="/products/shortcuts"
            step="Book"
            price={98.0}
          />
        </Col>
        <Col width="32%">
          <ProductBookCard
            title="<em>FIRST THINGS FIRST</em>"
            back={`The flip-chart guides a technician through quick electrical tests of a 14V electrical system with one battery in about 5 minutes.`}
            productLink="/products/first-things-first"
            step="Book"
            price={89.0}
          />
        </Col>
        <Col width="32%">
          <ProductBookCard
            title="The Starter Kit, H-111A"
            back={`This circuit board will teach you the principles of testing and measuring live vehicle electrical circuits with your DMM.`}
            productLink="/products/h-111a"
            step="Hands-On Kit"
            price={279.0}
          />
        </Col>
      </Row>
      <hr />
      <h2 className="text-center">Hands-On Training Kits</h2>
      <p className="text-center meta">
        These 4 kits require The Starter Kit, H-111A &mdash; make sure you purchase it first.
      </p>
      <Row>
        <Col width="23%">
          <ProductBookCard
            title="The DC Motor Troubleshooting Trainer, H-113"
            titleSmall={true}
            back={`The workbook explains brush less DC Motor operation and troubleshooting techniques unique to DC Motor circuits.`}
            productLink="/products/h-113"
            step="Hands-On Kit"
            price={199.0}
          />
        </Col>
        <Col width="23%">
          <ProductBookCard
            title="The Relay Troubleshooting Trainer, H-115"
            titleSmall={true}
            back={`The Relay Trainer connects to the Power Board from the Starter Kit. to explain how relays and relay circuits operate and how relay circuits fail.`}
            productLink="/products/h-115"
            step="Hands-On Kit"
            price={209.0}
          />
        </Col>
        <Col width="23%">
          <ProductBookCard
            title="The Wire Harness Troubleshooting Trainer, H-116"
            titleSmall={true}
            back={`This trainer connects to the Power Board from the Starter Kit to mini-electrical system we call "The M.E.S."`}
            productLink="/products/h-116"
            step="Hands-On Kit"
            price={375.0}
          />
        </Col>
        <Col width="23%">
          <ProductBookCard
            title="The CAN Bus Troubleshooting Trainer H-200"
            titleSmall={true}
            back={`Learn what the 60 ohm network is, how it works and how it can fail even when you read 60 ohms at DLC Pins 6 and 14.`}
            productLink="/products/h-200"
            step="Hands-On Kit"
            price={199.0}
          />
        </Col>
      </Row>
      <hr />
      <WhatNext />
    </div>
  </Layout>
)

export default AllTraining
